import React from 'react';


const Hero = function Hero() {

  return (

<div className="hero__artboard">
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
  <svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="white" strokeWidth="4">
      <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
  </svg>
</div>
  )
}


export default Hero;
