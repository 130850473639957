import React from 'react'
import { Link } from 'gatsby'
import "@fortawesome/fontawesome-pro/css/all.css"
import { Helmet } from "react-helmet";
import { withPrefix } from 'gatsby'



import Layout from '../components/layout'
import Header from '../components/header'
import Hero from '../components/hero'

const IndexPage = ({data}) => (
  <Layout>
    <main className="page--home">
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.metaDesc} />
        <meta property="og:image" content="https://www.robdimarzo.com/metaImage.png"/>
      </Helmet>
      <div className="hero__overlay"></div>
      <section className="slab slab--hero">
        <Header siteTitle="Rob DiMarzo" /> 
        <Hero /> 
        <div className="slab__inner">
          <h1 className="pageblock--title">
            Hello
          </h1>
          <div className="pageblock__content">
            <p>
              I’m Rob DiMarzo, a UX & Product Designer focused on crafting meaningful digital experiences.
            </p>
          </div>
        </div>
      </section>

      <section className="slab slab--projects" name="works">
        <div className="slab__inner">
          <div className="pageblock__title pageblock--casestudy">
            <h2>
              UX Case Studies           
            </h2>
          </div>
            <Link to="portfolio/prism/" className="project--primary project--prism">
              <div className="project__tile">
                  <h3>
                    {data.site.siteMetadata.assessTitle}
                  </h3>
                  <span>
                    {data.site.siteMetadata.assessDesc}
                  </span>
                </div>
            </Link>
            <Link to="portfolio/nexus/" className="project--primary project--nexus">
              <div className="project__tile">
                <h3>
                  {data.site.siteMetadata.nexusTitle}
                </h3>
                <span>
                  {data.site.siteMetadata.nexusDesc}
                </span>
              </div>
            </Link>
            <Link to="portfolio/flux/" className="project--primary project--flux">
              <div className="project__tile">
                <h3>
                  {data.site.siteMetadata.fluxTitle}
                </h3>
                <span>
                  {data.site.siteMetadata.fluxDesc}
                </span>
              </div>
            </Link>
            <Link to="portfolio/tax-design-system" className="project--primary project--designsystem">
              <div className="project__tile">
                  <h3>
                    {data.site.siteMetadata.designsystemTitle}
                  </h3>
                  <span>
                    {data.site.siteMetadata.designsystemDesc}
                  </span>
                </div>
            </Link>


            <div className="pageblock__title">
              <h2>
                UI Experiments          
              </h2>
            </div>
            <Link to="portfolio/solarcssystem/" className="project--secondary project--solarcssystem">
              <div className="project__tile">
                <h3>
                  Solar CSSystem
                </h3>
              </div>
            </Link>
            <Link to="portfolio/nutritiontransform/" className="project--secondary project--nutritionalfact">
              <div className="project__tile">
                <h3>
                  Nutrition Label Transformation
                </h3>
              </div>
            </Link>
            <Link to="portfolio/rgbcmyk" className="project--secondary project--rgbcmyk">
              <div className="project__tile">
                <h3>
                Visualizing RGB & CMYK
                </h3>
              </div>
            </Link>
            <Link to="portfolio/trianglefusion" className="project--secondary project--trianglefusion">
              <div className="project__tile">
                <h3>
                Infinite SVG Triangle Fusion
                </h3>
              </div>
            </Link>
            <Link to="portfolio/maptransform" className="project--secondary project--maplist">
              <div className="project__tile">
                  <h3>
                    Map List Transformation
                  </h3>
              </div>
            </Link>
            <Link to="portfolio/uranucss" className="project--secondary project--uranus">
              <div className="project__tile">
                <h3>
                UranuCSS
                </h3>
              </div>
            </Link>


        </div>
      </section>
    </main>
  </Layout>
)


export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        metaDesc
        fluxTitle
        fluxDesc
        nexusTitle
        nexusDesc
        assessTitle
        assessDesc     
        designsystemTitle
        designsystemDesc 
      }
    }
  }
`

export default IndexPage
